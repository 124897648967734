<template>
  <div>
    <!-- 列表内容 -->
    <div class="card_list">
      <!-- 列表内容项 -->
      <div class="card_list_item" v-for="(item,index) in list" :key="index" @click="openDetails(item.id)">
        <!-- 卡片图片 -->
        <div class="card_list_itemImg">
          <img :src="item.cover" />
        </div>
        <!-- 卡片内容 -->
        <div class="card_list_itemContent">
          <div class="card_list_itemContentText">{{item.title}}</div>
          <!-- 卡片标题 -->
          <!-- <el-popover placement="top-start" width="200" trigger="hover" :open-delay="500">
            <user-model></user-model> -->
            <!-- 发布人 -->
            <div class="card_list_itemContentUser" slot="reference">
              <div>
                <img :src="item.picture" />
              </div>
              <div>{{item.username}}</div>
            </div>
          <!-- </el-popover> -->
        </div>
        <!-- 卡片数据 -->
        <div class="card_list_itemData">
          <div>
            <div>
              <i class="el-icon-view"></i>
            </div>
            <div>{{item.visit_num}}</div>
          </div>
          <div>
            <div>
              <i class="iconfont icondianzan"></i>
            </div>
            <div>{{item.like_num}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userModel from '@/components/userModel.vue';

export default {
  props:['list'],
  data() {
    return {
      
    };
  },
  components:{
    userModel
  },
  methods: {
    // 打开详情
    openDetails(e){
      this.$router.push({
        path: '/HomeDetails',
        query:{
          id: e
        }
      })
    }
  },
};
</script>
<style scoped>
/* 卡片内容列表 */
.card_list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.card_list_item {
  width: 23%;
  font-size: 14px;
  border-radius: 10px;
  margin: 1%;
  box-sizing: border-box;
  background-color: #fff;
  transition: box-shadow 0.5s;
}
.card_list_item:hover {
  box-shadow: 7px 7px 7px #e3e3e3, -7px -7px 7px #f4f4f4;
}
.card_list_itemImg {
  width: 100%;
  height: 200px;
}
.card_list_itemImg > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s;
  border-radius: 10px;
}
.card_list_itemImg > img:hover {
  transform: scale(1.2);
}
.card_list_itemContent {
  cursor: pointer;
  box-sizing: border-box;
  margin: 15px;
  border-bottom: 1px solid #e3e3e3;
}
.card_list_itemContentText {
  box-sizing: border-box;
  padding: 20px 10px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.card_list_itemContentUser {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.card_list_itemContentUser > div {
  box-sizing: border-box;
  margin: 20px 10px;
}
.card_list_itemContentUser img {
  border-radius: 100%;
  width: 30px;
  height: 30px;
}
.card_list_itemData {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card_list_itemData > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card_list_itemData {
  padding: 20px;
  color: #666;
  box-sizing: border-box;
}
.card_list_itemData i {
  font-size: 20px;
  margin: 0 10px;
  box-sizing: border-box;
}
@media screen and (min-width: 2000px) and (max-width: 4500px) {
  .topicDetails {
    width: 80%;
  }
  .topicDetails_left {
    height: 50vh;
  }
  .card_list_itemImg{
    height: 20vh;
  }
}
</style>