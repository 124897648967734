<template>
  <div class="topicDetails">
    <div class="topicDetails_top">
      <div class="topicDetails_left">
        <img :src="topicDetails.cover" />
      </div>
      <div class="topicDetails_right">
        <div class="topicDetails_right_main">
          <div>{{ topicDetails.title }}</div>
          <div>{{ topicDetails.info }}</div>
        </div>
        <div class="topicDetails_right_footer">
          <div>
            <!-- <div>
              <i class="iconfont iconaixin-xian"></i>
            </div>
            <div>全部收藏</div> -->
          </div>
          <div>
            <!-- <div>
              <i class="el-icon-view"></i>
            </div>
            <div>查看往期专题</div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="topicDetails_main">
      <topic-card :list="list"></topic-card>
    </div>
  </div>
</template>

<script>
import topicCard from "./components/topicCard.vue";
export default {
  data() {
    return {
      topicDetails: "",
      list: [],
    };
  },
  components: {
    topicCard,
  },
  created() {
    this.getTopicDetails();
  },
  methods: {
    getTopicDetails() {
      let id = this.$route.query.id;
      this.$axios({
        url: this.$api + "/auth/ppt/album/deails",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        params: {
          item_id: id,
        },
      }).then((res) => {
        this.topicDetails = res.data.data.album;
        this.list = res.data.data.list;
      });
    },
  },
};
</script>

<style scoped>
/* 主题图和简介 */
.topicDetails {
  font-size: 14px;
  color: #666;
  width: 1500px;
  margin: 10px auto;
}
.topicDetails_top {
  display: flex;
  justify-content: space-between;
}
.topicDetails_left {
  width: 70%;
  height: 500px;
}
.topicDetails_left > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.topicDetails_right {
  padding: 1.2% 1.2% 0;
  box-sizing: border-box;
  width: 28%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #e3e3e3;
  border-radius: 15px;
}
.topicDetails_right_main {
  height: 90%;
}
.topicDetails_right_main > div:nth-of-type(1) {
  font-size: 18px;
  font-weight: 600;
}
.topicDetails_right_main > div:nth-of-type(2) {
  margin-top: 20px;
  color: #666;
}
.topicDetails_right_footer {
  cursor: pointer;
  border-top: 1px solid #e3e3e3;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topicDetails_right_footer > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.topicDetails_right_footer > div i {
  font-size: 16px;
  margin: 5px;
}
@media screen and (min-width: 2000px) and (max-width: 4500px) {
  .topicDetails {
    width: 80%;
  }
  .topicDetails_left {
    height: 50vh;
  }
}
</style>